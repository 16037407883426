import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import Button from "../../Common/Button";
import HeroVideo from "../../Website/Hero/hero.mp4";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import clutchImage from "../../../img/clutch.png";

const localData = {
  en: {
    where: "Where",
    products: "exceptional products",
    comeToLife: "come to life.",
    paragraph1: "We design and build beautiful products for your business.",
    paragraph2: "From startups to fortune 500 companies.",
    pargraph3: "From Lisbon to the world.",
    button: "See our work",
  },
  pt: {
    where: "Onde",
    products: "produtos excepcionais",
    comeToLife: "ganham vida.",
    paragraph1: "We design and build beautiful products for your business. ",
    paragraph2: "From startups to fortune 500 companies.",
    pargraph3: "Lisboa para o mundo.",
    button: "Veja nosso trabalho",
  },
};

function Hero({ selectedLanguage }) {
  useEffect(() => {
    let timer1 = setTimeout(() => {
      const vid = document.getElementById("heroVideo");
      vid.click();
    }, 5000);

    return () => {
      clearTimeout(timer1);
    };
  });
  return (
    <div className="broCode-hero bk-about-area" id="section-about">
      <Row className="about--creative align-items-lg-center brocode-content">
        <Col lg={5} xs={12} className="mt_md--40 mt_sm--40 col-xl-6">
          <div className="content pl_md--5 pl_sm--5">
            <div
              data-sal="slide-up"
              data-sal-duration="1400"
              data-sal-easing="ease-in"
            >
              <div className="title">
                <Fade opposite bottom duration={2500}>
                  <h2>{localData[selectedLanguage].where}</h2>
                </Fade>
                <Fade opposite bottom duration={2500}>
                  <h2>{localData[selectedLanguage].products}</h2>
                </Fade>
                <Fade opposite bottom duration={2500}>
                  <h2>
                    <span className="theme-creative">
                      {localData[selectedLanguage].comeToLife}
                    </span>
                  </h2>
                </Fade>
              </div>
            </div>

            <Fade opposite bottom duration={3000}>
              <p className="mt-4 mb-0" text-break>
                {localData[selectedLanguage].paragraph1}
              </p>
              <p text-break>{localData[selectedLanguage].paragraph2}</p>
            </Fade>
            <Fade opposite bottom duration={3000}>
              <p> {localData[selectedLanguage].paragraph3}</p>
            </Fade>

            <Fade bottom duration={3500}>
              <Row className="m-0">
                <AniLink cover direction="up" to="/work" hex="#fa4180">
                  <Button color="red" category="See our work">
                    {" "}
                    {localData[selectedLanguage].button}
                  </Button>
                </AniLink>
                <a href="https://clutch.co/profile/brocode-studio#highlights" target="_blank">
                <img className="hero-clutch" src={clutchImage} />
                </a>
              </Row>
            </Fade>
          </div>
        </Col>

        <Col lg={7} className="no-mobile d-block text-center col-xl-6">
          <Fade bottom duration={2000}>
            <video
              id="heroVideo"
              className="my-3 home-banner--img"
              preload="auto"
              loop
              muted
              playsInline
              autoPlay
            >
              <source src={HeroVideo} type="video/mp4" />
            </video>
          </Fade>
        </Col>
      </Row>
    </div>
  );
}

export default Hero;
