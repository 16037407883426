import React from "react";
import { Row, Col } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import WorkSample from "./WorkSample";
import Button from "../../Common/Button";

// logos imports
import skippitLogo from "../../../img/company-logos/skippit.svg";
import sublimeLogo from "../../../img/company-logos/sublime.svg";
import martinsWines from "../../../img/company-logos/martinswines.svg";

const workList = [
  {
    logo: skippitLogo,
    link: "/work/skippit",
    name: "Skippit App",
    id: 0,
  },
  {
    logo: sublimeLogo,
    link: "/work/sublimecomporta",
    name: "Sublime Comporta",
    id: 1,
  },
  {
    logo: martinsWines,
    link: "/work/martinswine",
    name: "Martins Wine Advisor",
    id: 3,
  },
];

const localData = {
  en: {
    someOf: "Some of our",
    work: "work.",
    button: "See all",
  },

  pt: {
    someOf: "Alguns de nossos ",
    work: "trabalhos.",
    button: "Ver tudo",
  },
};

function OurWork({ selectedLanguage }) {
  const getLocalLink = (link) => {
    return selectedLanguage === "pt" ? "/pt" + link : link;
  };

  return (
    <div className="broCode-ourWork-section mt--40">
      <Row className="brocode-content mt--40">
        <Col xs={12}>
          <div className="section-title--between wow move-up w-100">
            <div className="title">
              <h3 className="w-100 text-lg-center text-left">
                {localData[selectedLanguage].someOf}
                <span className="theme-creative">
                  {" " + localData[selectedLanguage].work}
                </span>
              </h3>
            </div>
          </div>
        </Col>
      </Row>

      <div className="mt--40 wow move-up">
        <Fade bottom duration={2000}>
          <Row className="m-0">
            {workList.map((workSample) => (
              <WorkSample
                name={workSample.name}
                logo={workSample.logo}
                link={getLocalLink(workSample.link)}
                id={workSample.id}
              />
            ))}
          </Row>
        </Fade>
      </div>
      <Row className="justify-content-center mt--40 mb-5">
        <AniLink cover direction="up" to="/work" hex="#fa4180">
          <Fade bottom duration={2000}>
            <Button color="red" category="See all">
              {localData[selectedLanguage].button}
            </Button>
          </Fade>
        </AniLink>
      </Row>
    </div>
  );
}

export default OurWork;
