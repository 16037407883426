import React, { useState, useEffect } from "react";
import Layout from "../components/Common/Layout";
import Hero from "../components/Website/Hero";
import OurServices from "../components/Website/OurServices";
import TrustUs from "../components/Website/TrustUs";
import OurWork from "../components/Website/OurWork";
import Driven from "../components/Website/Driven";

const localData = {
  en: {
    title: "Brocode Studio || Home page",
    description:
      "Experts creating Apps, websites, products, brands and pretty much anything you can imagine.",
  },
  pt: {
    title: "Brocode Studio || Pagina inicial",
    description:
      "Especialistas em criação de aplicativos, sites, produtos, marcas e praticamente qualquer coisa que você possa imaginar.",
  },
};

const WebsiteIndex = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (window.location.pathname.includes("/pt")) {
      setSelectedLanguage("pt");
    } else {
      setSelectedLanguage("en");
    }
  }, []);

  return (
    <Layout
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      selectedLanguage={selectedLanguage}
      title={localData[selectedLanguage].title}
      description={localData[selectedLanguage].description}
    >
      <Hero selectedLanguage={selectedLanguage} />
      <OurWork selectedLanguage={selectedLanguage} />
      <OurServices
        setModalVisible={setModalVisible}
        selectedLanguage={selectedLanguage}
      />
      <TrustUs selectedLanguage={selectedLanguage} />
      <Driven
        setModalVisible={setModalVisible}
        selectedLanguage={selectedLanguage}
      />
    </Layout>
  );
};

export default WebsiteIndex;
