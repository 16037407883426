import React, { useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import Particles from "react-particles";
import Fade from "react-reveal/Fade";
import Button from "../../Common/Button";
import { loadSlim } from "tsparticles-slim"


const localData = {
  en: {
    driven: "We are driven",
    make: "to make",
    products: "great products",
    button: "See our work",
  },
  pt: {
    driven: "Nós somos dirigidos",
    make: "fazer",
    products: "ótimos produtos",
    button: "Veja nosso trabalho",
  },
};
function Driven({ setModalVisible, selectedLanguage }) {
  const particlesInit = useCallback(async engine => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    //await loadFull(engine);
    await loadSlim(engine);
}, []);

const particlesLoaded = useCallback(async container => {
  await console.log(container);
}, []);

  return (
    <div className="broCode-driven mt--40">
      <Particles
      canvasClassName="canvas-element"
       id="tsparticles"
       init={particlesInit}
       loaded={particlesLoaded}
        options={{
        style:{
          position: 'absolute'
        },
          particles: {
            number: {
              value: 160,
              density: {
                enable: false,
              },
            },
            size: {
              value: 3,
              random: true,
              anim: {
                speed: 4,
                size_min: 0.3,
              },
            },
            line_linked: {
              enable: false,
            },
          
            move: {
              enable:true,
              random: true,
              speed: 1,
              direction: "top",
              out_mode: "out",
            },
          },
          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: "bubble",
              },
              onclick: {
                enable: true,
                mode: "repulse",
              },
            },
            modes: {

              bubble: {
                distance: 250,
                duration: 2,
                size: 0,
                opacity: 0,
              },
              repulse: {
                distance: 400,
                duration: 4,
              },
            },
          },
         retina_detect:true
        }}
      />
      <div class="creative-gradient--1">
        <Row>
          <Col xs={12}>
            <div className="content title pl_md--5 pl_sm--5 brocode-content">
              <h3>{localData[selectedLanguage].driven}</h3>
              <h3>{localData[selectedLanguage].make}</h3>
              <h3>{localData[selectedLanguage].products}</h3>

              <Row className="m-0">
                <Fade bottom duration={2000}>
                  <Button onClick={() => setModalVisible(true)} color="red" category="See our work">
                    {localData[selectedLanguage].button}
                  </Button>
                </Fade>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Driven;
