import React from "react";
import { Row, Col } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import SwiperCore, { Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import ServicePellet from "../ServicePellet";
import Button from "../../Common/Button";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/scrollbar/scrollbar.scss";

// install Swiper components
SwiperCore.use([Scrollbar]);

// localData
const localData = {
  en: {
    What: "What we",
    do: "do",
    button: "Let's work together",
    broCodeServices: [
      [
        {
          title: "UI & UX Design",
          content:
            "We create functional, modern, beautiful and intuitive designs that will make your product stand out.",
        },
        {
          title: "Development",
          content:
            "We develop custom solutions to bring your vision to life using the right platforms to enhance your product.",
        },
        {
          title: "Mobile Apps",
          content:
            "We provide a broad range of high quality mobile applications for different operating systems.",
        },
      ],
      [
        {
          title: "Branding",
          content:
            "We know all about identity, and we can make your brand visible and unique.",
        },
        {
          title: "SEO / Content Strategy",
          content:
            "We provide strategy to attract, acquire and engage your audience.",
        },
        {
          title: "Mentoring",
          content:
            "We are eager to learn everyday and we love to teach. Surround yourself with great professionals and you will grow. Lets grow together.",
        },
      ],
    ],
  },
  pt: {
    What: "O que nós ",
    do: "fazemos",
    button: "Vamos trabalhar juntos",
    broCodeServices: [
      [
        {
          title: "UI & UX Design",
          content:
            "Criamos designs funcionais, modernos, bonitos e intuitivos que farão com que o seu produto se destaque.",
        },
        {
          title: "Development",
          content:
            "Desenvolvemos soluções personalizadas para dar vida à sua visão, usando as plataformas certas para aprimorar o seu produto",
        },
        {
          title: "Mobile Apps",
          content:
            "Oferecemos uma ampla gama de aplicativos móveis de alta qualidade para diferentes sistemas operacionais.",
        },
      ],
      [
        {
          title: "Branding",
          content:
            "Sabemos tudo sobre identidade e podemos tornar sua marca visível e única.",
        },
        {
          title: "SEO / Content Strategy",
          content:
            "Oferecemos estratégia para atrair, adquirir e engajar seu público.",
        },
        {
          title: "Mentoring",
          content:
            "Estamos ansiosos para aprender todos os dias e amamos ensinar. Cerque-se de grandes profissionais e você vai crescer. Vamos crescer juntos.",
        },
      ],
    ],
  },
};

function OurServices({ setModalVisible, selectedLanguage }) {
  return (
    <div className="broCode-ourServices mb-5  brocode-content">
      <Row>
        <Col xs={12}>
          <Fade bottom duration={2000}>
            <div className="title">
              <h3 className="text-left text-lg-center">
                {localData[selectedLanguage].where}
                <span className="theme-creative">
                  {localData[selectedLanguage].where}
                </span>
              </h3>
            </div>
          </Fade>
        </Col>
      </Row>

      {localData[selectedLanguage].broCodeServices.map((row) => (
        <Fade bottom duration={2000}>
          <Row className="broCode-service-content d-none d-lg-flex">
            {row.map((service) => (
              <Col lg={4} xs={12}>
                <Fade bottom duration={2000}>
                  <ServicePellet service={service.title} />
                </Fade>
                <p class="p-width">{service.content}</p>
              </Col>
            ))}
          </Row>
        </Fade>
      ))}

      <Row className="d-lg-none d-block mx-0">
        <Swiper
          spaceBetween={30}
          slidesPerGroup={1}
          slidesPerView={"auto"}
          setWrapperSize={true}
          direction="horizontal"
          scrollbar={{ draggable: true, parallax: true }}
        >
          {localData[selectedLanguage].broCodeServices.flat().map((service) => (
            <SwiperSlide>
              <ServicePellet service={service.title} />
              <p class="p-width">{service.content}</p>
            </SwiperSlide>
          ))}
        </Swiper>
      </Row>
      <Row className="justify-content-center services-button-wrapper">
        <Fade bottom duration={2000}>
          <Button
            onClick={() => setModalVisible(true)}
            color="red"
            category="Let's work together"
          >
            {localData[selectedLanguage].button}
          </Button>
        </Fade>
      </Row>
    </div>
  );
}

export default OurServices;
