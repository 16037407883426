import React from "react";
import { Row, Col } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Button from "../../Common/Button";
import CompaniesLogos from "./CompaniesLogos";

const localData = {
  en: {
    companies: "Companies that",
    trust: "trust us.",
    paragraph: "We work with clients all over the world. It's better together.",
    title: "Brocode Studio || Home page",
    description:
      "Experts creating Apps, websites, products, brands and pretty much anything you can imagine.",
    button: "See all",
  },
  pt: {
    companies: "Empresas que",
    trust: " confie em nós.",
    paragraph: "Trabalhamos com clientes em todo o mundo. É melhor junto.",
    title: "Brocode Studio || Pagina inicial",
    description:
      "Especialistas em criação de aplicativos, sites, produtos, marcas e praticamente qualquer coisa que você possa imaginar.",
    button: "Ver tudo",
  },
};

function TrustUs({ selectedLanguage }) {
  return (
    <div className="broCode-trustUs mt--40 creative-portfolio section-pb-100 companies">
      <Row>
        <Col xs={12}>
          <div className="section-title--between wow move-up companies-desktop w-100">
            <Fade bottom duration={2000}>
              <div className="title w-100">
                <h3 className="text-lg-center text-left w-100">
                  {localData[selectedLanguage].companies} <br />
                  <span className="theme-creative">
                    {localData[selectedLanguage].trust}
                  </span>
                </h3>
              </div>
            </Fade>
          </div>
        </Col>
      </Row>
      <Row className="row justify-content-center mt--10 content-text--desktop">
        <Col xs={12}>
          <Fade bottom duration={2000}>
            <p className="w-100 text-lg-center text-left">
              {localData[selectedLanguage].paragraph}
            </p>
          </Fade>
        </Col>
      </Row>
      <CompaniesLogos />
      <Row className="mt--40 justify-content-center">
        <Fade bottom duration={2000}>
          <AniLink cover direction="up" to="/work" hex="#fa4180">
            <Button color="red" category="See all">
              {" "}
              {localData[selectedLanguage].button}
            </Button>
          </AniLink>
        </Fade>
      </Row>
    </div>
  );
}

export default TrustUs;
